import { ReactElement } from 'react'
import { Box } from '@material-ui/core'
import Navbar from 'manage-tritag/components/navbar'
import MobileNavbar from 'manage-tritag/components/navbar/mobile'
import { useMediaQuery } from 'react-responsive'
import { PageProps } from 'gatsby'
import image from '../images/home_page_placeholder.jpg'

const IndexPage = (props: PageProps): ReactElement => {
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  return (
    <>
      {isMobile ? (
        <MobileNavbar path={props.location.pathname} bg="#008174" />
      ) : (
        <Navbar absolute />
      )}
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          zIndex: '-99',
        }}
      >
        <video
          style={{ width: '100vw', height: '100vh', objectFit: 'cover' }}
          playsInline
          autoPlay
          muted
          loop
          poster={image}
          id="bgvid"
        >
          <source
            src="https://tritagrugbypublic.s3.ap-southeast-2.amazonaws.com/tri-tag-rugby-about.mp4"
            type="video/mp4"
          />
        </video>
      </Box>
    </>
  )
}

export default IndexPage
